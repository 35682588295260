<template>
	<div
		class="d-flex page-wrapper flex-column h-100 justify"
		:class="{'onSidebarOpen' : sidebarVisible}"
	>
		<div
			class="m-auto card-receipt bg-white d-flex flex-column align-items-center"
			id="cardReceipt"
		>
			<img
				class="ihs-logo"
				src="@/assets/logo/ihs-logo-bw.svg"
				alt=""
			>
			<p class="font-12 text-center px-2 mt-3 mb-1">Jl. Sederhana No.63, Pasteur, Kec. Sukajadi, Kota Bandung, Jawa Barat 40161</p>
			<span class="text-center font-12">(022) 2033144</span>
			<div class="text-center font-12 mt-2">
				Struk Pembelian
			</div>
			<div class="text-center font-12">
				{{order.invoice_number}}
			</div>
			<div class="cashier-container w-100 my-2 py-2 font-12">

				<div class=" d-flex justify-content-between">
					<span>Kasir: {{cashierName}} </span>
					<span>{{order.created_at}} - {{hourCreatedAt}} </span>
				</div>
				<div class=" d-flex justify-content-between">
					<span>Pelanggan </span>
					<span>{{order.user.name}}</span>
				</div>
			</div>
			<div
				class="product font-12 align-self-start align-items-center w-100"
				v-for="product in order.products"
				:key="product.id"
			>
				<p class="product-name mb-0">{{product.name}}</p>
				<div class="ps-3 d-flex justify-content-between w-100">
					<span> {{product.quantity}} x {{formatRupiah(product.price, 'Rp. ')}} </span>
					<span> {{formatRupiah(product.quantity * product.price, 'Rp.')}}</span>
				</div>
					<div class="ps-3 d-flex justify-content-between w-100" v-if="product.discount">
					<span> Diskon </span>
					<span> - {{formatRupiah((product.price - product.discounted_price) * product.quantity, 'Rp.')}}</span>
				</div>
			</div>
			<div
				class="d-flex justify-content-between align-self-stretch font-12"
				v-if="order.payment.total_additional_cost"
			>
				<span>Lainnya</span>
				<span>{{formatRupiah(order.payment.total_additional_cost, 'Rp. ')}}</span>
			</div>
			<div
				class="d-flex justify-content-between align-self-stretch font-12"
				v-if="order.payment.total_voucher"
			>
				<span>{{order.payment.voucher.code}}</span>
				<span>-{{formatRupiah(order.payment.total_voucher, 'Rp. ')}}</span>
			</div>
				<div
				class="d-flex justify-content-between align-self-stretch font-12"
				v-if="order.payment.total_point"
			>
				<span>Potongan Poin</span>
				<span>-{{formatRupiah(order.payment.total_point, 'Rp. ')}}</span>
			</div>
			<div class="total-container d-flex flex-column pt-2 w-100 mt-3">
				<div class="d-flex justify-content-between">
					<span class="font-18 font-500">Total</span>
					<span class="font-18 font-500">{{formatRupiah(order.payment.total_payment, 'Rp.')}}</span>
				</div>
			</div>

			<span class="payment-divider w-100 text-center overflow-hidden font-14 mt-1">Payment</span>
			<div class="payment-detail d-flex flex-column align-self-stretch">
				<div
					class="d-flex font-12"
					v-if="order.payment.payment_method_type.toLowerCase() === 'tunai'"
				>
					<span>Tunai</span>
					<span class="ms-auto">
						{{paymentInput}}
					</span>
				</div>
				<div
					class="d-flex font-12"
					v-if="order.payment.payment_method_type.toLowerCase() === 'tunai'"
				>
					<span>Kembali</span>
					<span class="ms-auto">
						{{ formatRupiah( paymentInputInteger - parseInt(order.payment.total_payment), 'Rp. ')}}
					</span>
				</div>
					<div
					class="d-flex"
					v-if="order.payment.payment_method_type.toLowerCase() === 'virtual account'"
				>
					<span class="font-12">
						{{order.payment.payment_method_type}}
					</span>

				</div>
					<div
					class="d-flex"
					v-if="order.payment.payment_method_type.toLowerCase() === 'qris'"
				>
					<span class="font-12">
						{{order.payment.payment_method_type}}
					</span>

				</div>
				<div
					class="d-flex"
					v-else
				>
					<span class="font-12" v-if="order.payment.payment_method_type !== 'Tunai'">
						{{order.payment.payment_method_type}} - {{order.payment.payment_method_name}}
					</span>

				</div>
			</div>

			<div class="receipt-footer d-flex flex-column align-self-stretch mt-2 py-2">
				<span class="text-center font-12">HARGA SUDAH TERMASUK PAJAK</span>
				<span class="text-center font-12 mb-3">Terima Kasih telah berbelanja di <br> Inti Health Store</span>
				<p class="text-center font-12 mb-0">
					Barang yang sudah dibeli tidak bisa ditukar / dikembalikan
				</p>
				
			</div>
			<div class="d-flex flex-column w-100 contact">
				<div class="d-flex font-12">
					<span>No Telepon</span>
					<span class="ms-auto"> 022 - 2033144</span>
				</div>
				<div class="d-flex font-12">
					<span>Whatsapp</span>
					<span class="ms-auto">
						082128777344
					</span>
				</div>
				<div class="d-flex font-12">
					<span>Instagram</span>
					<span class="ms-auto">
						intihealthstoresederhana
					</span>
				</div>
				<div class="d-flex font-12">
					<span>Website</span>
					<span class="ms-auto">
						intihealthstore.com
					</span>
				</div>
			</div>

			<div class="end-border"></div>
			

		</div>
		<div class="footer mt-auto d-flex fixed-bottom bg-white py-3 gap-2">
			<div class="flex-grow-1 pl-3 me-2 d-flex align-items-center">
				<div
					class=" d-flex align-items-center w-100 mx-3 px-5 return-price-container"> 
					<button class="button button--outline-grey font-700 d-flex align-items-center gap-3" @click="$router.push('/')">
						<img src="@/assets/icons/back-icon.svg" alt="">
						Kembali
					</button>   
					<template v-if="order.payment.payment_method_type.toLowerCase() === 'tunai'">
						<div class="ms-auto me-2 mt-1">Total Kembalian:</div>
						<span class="color-primary font-24 font-500">{{formatRupiah( paymentInputInteger - parseInt(order.payment.total_payment), 'Rp. ')}}</span>
					</template>
					
				</div>
			</div>
			<button
				class="button button--primary me-4 px-5"
				v-print="'#cardReceipt'"
			>
				<img
					src="@/assets/icons/print.svg"
					alt="print icon"
					class="me-1"
				>
				Print Struk
			</button>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import IhsMixin from "@/mixins/ihs.mixins.js";
import print from "vue-print-nb";

export default {
	mixins: [IhsMixin],
	directives: {
		print
	},
	computed: {
		...mapState("home", ["sidebarVisible"]),
		...mapState("order", ["order"]),
		cashierName() {
			return JSON.parse(localStorage.activeCashier).name;
		},
		paymentInput() {
			return localStorage.paymentInput;
		},
		paymentInputInteger() {
			return parseInt(localStorage.paymentInput.replace(/\D/g, ""));
		},
		hourCreatedAt() {
			let b = this.order.verbose_created_at;

			let split = b.split(" ");
			return split[3].slice(0, -3);
		}
	},
	methods: {
		...mapMutations("home", ["setSidebarVisible"])
	},
	created() {
		this.setSidebarVisible(false);
	}
};
</script>

<style lang="scss">
@media print {
	.ihs-logo {
		width: 109px !important;
		height: 40px !important;
		object-fit: cover !important;
	}
	.font-14,
	.font-12 {
		font-size: 12px !important;
	}
	.font-18 {
		font-size: 14px !important;
	}
	.navbar {
		display: none !important;
	}
	.footer {
		display: none !important;
	}
	.card-receipt {
		width: 56mm !important;
		border: 0 !important;
		padding: 10px 0 !important;
		border-radius: 0 !important;
		margin: 0 !important;
	}
	html {
		padding-top: 10x !important;
		padding-bottom: 10px !important;
		margin-bottom: 10px !important;
	}
	.page-wrapper {
		padding: 0 !important;
	}
	
}
</style>
<style lang="scss" scoped>
.card-receipt {
	padding: 30px;
	width: 315px;
	border: 0.5px solid #cdcdce;
	border-radius: 8px;
}

.cashier-container {
	border-top: 1px dashed black;
	border-bottom: 1px dashed black;
}

.total-container {
	border-top: 1px dashed black;
}
.product-name {
	width: 60%;
}
.receipt-footer {
	border-top: 1px dashed black;
	border-bottom: 2px dashed black;
}

.payment-divider:before,
.payment-divider:after {
	border-top: 1px dashed black;
	content: "";
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 50%;
}

.payment-divider:before {
	right: 0.5em;
	margin-left: -50%;
}

.payment-divider:after {
	left: 0.5em;
	margin-right: -50%;
}

.page-wrapper {
	background: #f5f5f5;
	transition: margin 400ms ease-in-out;
	overflow-x: hidden;
	position: relative;
	padding-top: 24px;
	padding-bottom: 100px;
	&.onSidebarOpen {
		margin-left: 78px;
	}
}

.return-price-container {
		border-right: 1px solid #E0E0E0;
		padding-right: 20px;
}

.contact {
	border-top: 2px dashed black;
	margin-top: 2px;
	padding-top: 12px;
}

.end-border {
      margin-top: 50px;
      border-top: 1px dashed black;
      width: 100%;

  }
</style>

